module.exports = [{
      plugin: require('/builds/ragitlab/travelpro-rentals-marketing-site/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/ragitlab/travelpro-rentals-marketing-site/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/builds/ragitlab/travelpro-rentals-marketing-site/src/cms/cms.js","enableIdentityWidget":false},
    },{
      plugin: require('/builds/ragitlab/travelpro-rentals-marketing-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
