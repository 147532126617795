// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/builds/ragitlab/travelpro-rentals-marketing-site/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-infopages-info-pages-template-js": () => import("/builds/ragitlab/travelpro-rentals-marketing-site/src/pages/infopages/infoPagesTemplate.js" /* webpackChunkName: "component---src-pages-infopages-info-pages-template-js" */),
  "component---src-pages-splashpages-splash-pages-template-js": () => import("/builds/ragitlab/travelpro-rentals-marketing-site/src/pages/splashpages/splashPagesTemplate.js" /* webpackChunkName: "component---src-pages-splashpages-splash-pages-template-js" */),
  "component---src-pages-404-js": () => import("/builds/ragitlab/travelpro-rentals-marketing-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/builds/ragitlab/travelpro-rentals-marketing-site/.cache/data.json")

